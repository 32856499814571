import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage, loadUser } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { themeStyle, themeStyleDark } from "../components/Components";
import SyncIcon from "@mui/icons-material/Sync";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Tasks() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [id, setId] = useState("");

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [taskSubject, setTaskSubject] = useState("");

  const colorList = [
    "#e5f5e0",
    state.auth.theme == "dark"
      ? themeStyleDark.secondaryButtonColor
      : themeStyle.secondaryButtonColor,
    "#fee6ce",
  ];
  const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
  const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

  const [isLoading, setIsLoading] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [taskId, setTaskId] = useState("");
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [selectedPage, setSelectedPage] = useState(
    state.auth.isLoading
      ? "ADD NEW TASK"
      : !state.auth.user.is_staff || !state.auth.user.is_manager
      ? "ADD NEW TASK"
      : params.get("tab")
      ? params.get("tab")
      : "USER TASKS"
  );
  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  function submitTask() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["task_subject"] = taskSubject;
    body["upload_date"] = moment().format("DD-MM-YYYY");

    axios
      .post(API_URL + "add_task", body, config)
      .then(() => {
        setIsSubmitting(false);
        setOpen2(false);
        dispatch(loadUser());
        setTaskSubject("");
        createAlert("SUCCESS", "Success.", "Task request submitted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setUsers(res.data);
      setCurrentTasks(res.data[userIndex]["tasks"]);
      setIsLoading(false);
    });
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setCurrentTasks(users[e.target.value]["tasks"]);
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (
        state.auth.user.is_staff ||
        state.auth.user.is_manager ||
        state.auth.user.is_hr
      ) {
        getData();
      } else {
        setIsLoading(false);
        setCurrentTasks(JSON.parse(state.auth.user.task_requests));
      }
    }
  }, []);

  if (state.auth.refresh) {
    console.log("ABAJSJSJS");
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      dispatch(loadUser());
      getData();
    } else {
      dispatch(loadUser());
    }
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function updateTask(update) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["task_id"] = taskId;
    body["edit_date"] = moment().format("hh:mm A Do MMM, YYYY");
    body["update"] = update;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "edit_task", body, config)
      .then((res) => {
        getData();
        setOpen3(false);
        createAlert("SUCCESS", "Success.", "Task marked " + update);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getPageContent() {
    if (selectedPage == "ADD NEW TASK") {
      return (
        <div style={{ width: "100%" }}>
          <Grid container spacing={5}>
            <Grid item md={8}>
              {JSON.parse(state.auth.user.tasks).length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No Task requests.</h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              {JSON.parse(state.auth.user.tasks).map((task, index) => (
                <div
                  onClick={() => {
                    setTaskId(task["id"]);
                    setOpen3(true);
                  }}
                  style={{
                    cursor: "pointer",
                    minHeight: "80px",
                    padding: "10px",
                    marginBottom: "15px",
                    borderRadius: "15px",
                    border:
                      task["status"] == "PENDING"
                        ? "1.5px solid" + state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor
                        : "none",
                    backgroundColor:
                      task["status"] == "PENDING"
                        ? "white"
                        : task["status"] == "DONE"
                        ? colorList[0]
                        : colorList[2],
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItemsL: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          lineHeight: "20px",
                          color:
                            task["status"] == "PENDING"
                              ? colorList2[1]
                              : task["status"] == "DONE"
                              ? colorList2[0]
                              : colorList2[2],
                        }}
                      >
                        {task["subject"]}
                      </div>
                      {task["activity"].map((acti) => (
                        <div
                          style={{
                            color: "black",
                          }}
                        >
                          {acti}
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "-15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          textAlign="center"
                          p={1}
                          style={{
                            height: "35px",
                            width: "35px",
                            borderRadius: "60px",
                            backgroundColor:
                              task["status"] == "PENDING"
                                ? colorList2[1]
                                : task["status"] == "DONE"
                                ? colorList2[0]
                                : colorList2[2],
                            color: "white",
                          }}
                        >
                          {task["status"] == "PENDING" ? (
                            <PriorityHighIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : task["status"] == "DONE" ? (
                            <CheckIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : task["status"] == "STARTED" ? (
                            <SyncIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : (
                            <PublishedWithChangesIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          )}
                        </Box>
                        <b
                          style={{
                            marginTop: "5px",
                            color:
                              task["status"] == "PENDING"
                                ? colorList2[1]
                                : task["status"] == "DONE"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "lighter",
                            }}
                          >
                            {task["status"]}
                          </span>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
            <Grid item md={4}>
              <div
                style={{
                  marginTop: "-25px",
                  marginBottom: "-15px",
                  fontWeight: "bold",
                  fontSize: "24px",
                }}
              >
                <h3>Add new Task</h3>
              </div>

              <div
                style={{
                  margin: "15px 0px 5px 0px",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "16px",
                }}
              >
                Task Subject
              </div>
              <TextField
                value={taskSubject}
                style={{ marginTop: "2px" }}
                onChange={(e) => setTaskSubject(e.target.value)}
                fullWidth
                placeholder="Task Subject"
                variant="outlined"
                size="small"
              />
              <br></br>
              <br></br>

              <Button
                onClick={() => {
                  setOpen2(true);
                }}
                style={{
                  width: "100%",
                  fontSize: "15px",
                  height: "35px",
                  marginTop: "-1px",
                  backgroundColor: themeStyle.primaryColor,
                }}
                variant="contained"
                component="span"
              >
                <p>Submit new task</p>
              </Button>
              {isSubmitting ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Grid container spacing={1.5}>
                {currentTasks.length == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No tasks for this user.</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {currentTasks.map((task, index) => (
                  <div
                    onClick={() => {
                      setTaskId(task["id"]);
                      setOpen3(true);
                    }}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      minHeight: "80px",
                      padding: "10px",
                      marginBottom: "15px",
                      borderRadius: "15px",
                      border:
                        task["status"] == "PENDING"
                          ? "1.5px solid" + state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor
                          : "none",
                      backgroundColor:
                        task["status"] == "PENDING"
                          ? "white"
                          : task["status"] == "DONE"
                          ? colorList[0]
                          : colorList[2],
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItemsL: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            lineHeight: "20px",
                            color:
                              task["status"] == "PENDING"
                                ? colorList2[1]
                                : task["status"] == "DONE"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          {task["subject"]}
                        </div>
                        {task["activity"].map((acti) => (
                          <div
                            style={{
                              color: "black",
                            }}
                          >
                            {acti}
                          </div>
                        ))}
                      </div>

                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "-15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            textAlign="center"
                            p={1}
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "60px",
                              backgroundColor:
                                task["status"] == "PENDING"
                                  ? colorList2[1]
                                  : task["status"] == "DONE"
                                  ? colorList2[0]
                                  : colorList2[2],
                              color: "white",
                            }}
                          >
                            {task["status"] == "PENDING" ? (
                              <PriorityHighIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : task["status"] == "DONE" ? (
                              <CheckIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : task["status"] == "STARTED" ? (
                              <SyncIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : (
                              <PublishedWithChangesIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            )}
                          </Box>
                          <b
                            style={{
                              marginTop: "5px",
                              color:
                                task["status"] == "PENDING"
                                  ? colorList2[1]
                                  : task["status"] == "DONE"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "lighter",
                              }}
                            >
                              {task["status"]}
                            </span>
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Grid>
            </Grid>
            <Grid item md={4}>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "14px",
                }}
              >
                Select User
              </span>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                variant={"outlined"}
                fullWidth
                value={userIndex}
                onChange={(e) => handleUserChange(e)}
              >
                {getUsersList()}
              </Select>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                <CircularProgress style={{ color: themeStyle.primaryColor }} />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  state.auth.user.is_staff || state.auth.user.is_manager ? (
                    <Grid container spacing={1.5} justifyContent={"flex-end"}>
                      <Grid item md={4}>
                        {selectedPage == "USER TASKS" ? (
                          <Button
                            fullWidth
                            value={"USER TASKS"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              USER TASKS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value={"USER TASKS"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonColor
                                  : themeStyle.secondaryButtonColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.secondaryButtonTextColor
                                    : themeStyle.secondaryButtonTextColor,
                              }}
                            >
                              USER TASKS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedPage == "ADD NEW TASK" ? (
                          <Button
                            fullWidth
                            value="ADD NEW TASK"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              ADD NEW TASK
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="ADD NEW TASK"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonColor
                                  : themeStyle.secondaryButtonColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.secondaryButtonTextColor
                                    : themeStyle.secondaryButtonTextColor,
                              }}
                            >
                              ADD NEW TASK
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Fragment />
                  )
                }
              />

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Add new task?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen2(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => submitTask()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open3}
                onClose={() => setOpen3(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open3}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen3(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Update this task status?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => updateTask("STARTED")}
                        >
                          STARTED
                        </Button>

                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => updateTask("IN REVIEW")}
                        >
                          IN REVIEW
                        </Button>

                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => updateTask("DONE")}
                        >
                          DONE
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor: themeStyle.backgroundColor,
                  height: "100vh",
                  width: "100vw",
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    }
  } else {
    return (
      <Fragment>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Tasks;
