export const themeStyleDark = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#31061C",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#31061C",
  primaryButtonTextColor: "white",
  secondaryButtonColor: "#DCB7D1",
  secondaryButtonTextColor: "#31061C",

};

export const themeStyle = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#31061C",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#31061C",
  primaryButtonTextColor: "black",
  secondaryButtonColor: "#DCB7D1",
  secondaryButtonTextColor: "#31061C",

};
