import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import moment from "moment";
import Fab from "@mui/material/Fab";
import { themeStyle, themeStyleDark } from "../components/Components";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Feedback() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState("GOOD");

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "COMPLAINTS"
  );

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setUsers(res.data);
      setFeedback(res.data[0]["feedback"]);
      setIsLoading(false);
    });
  }

  function submitFeedback() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = users[userIndex]["email"];
    body["subject"] = subject;
    body["description"] = description;
    body["date"] = moment().format("DD-MM-YYYY");
    body["rating"] = rating;

    axios.post(API_URL + "submit_feedback", body, config).then((res) => {
      setIsSubmitting(false);
      getData();
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setFeedback(users[e.target.value]["feedback"]);
  }

  var colorIndex = 0;

  function getContent() {
    if (state.auth.user.is_staff) {
      return (
        <Fragment>
          <Grid container spacing={5}>
            <Grid item md={8}>
              {feedback.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No feedback submitted for this user.
                    </h4>
                  </div>
                </div>
              ) : (
                feedback.map((feed, index) => (
                  <Fragment>
                    <Box
                      p={2}
                      style={{
                        marginBottom: "15px",
                        borderRadius: "15px",
                        border: "2px solid #d7ecff",
                        backgroundColor: themeStyle.primaryCardColor,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "90%" }}>
                          <div style={{ marginTop: "-30px" }}>
                            <h3>{feed["subject"]}</h3>
                          </div>
                          <div style={{ marginTop: "-25px" }}>
                            <h4>{feed["class"]}</h4>
                          </div>
                          <div
                            style={{
                              marginTop: "-15px",
                              marginBottom: "5px",
                              lineHeight: "18px",
                            }}
                          >
                            {feed["description"]}
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Fragment>
                ))
              )}
            </Grid>
            <Grid item md={4}>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "14px",
                }}
              >
                Select User
              </span>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                variant={"outlined"}
                fullWidth
                value={userIndex}
                onChange={(e) => {
                  handleUserChange(e);
                }}
              >
                {getUsersList()}
              </Select>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item md={8}>
              {feedback.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No feedback submitted.</h4>
                  </div>
                </div>
              ) : (
                feedback.map((feed, index) => (
                  <Fragment>
                    <Box
                      p={2}
                      style={{
                        marginBottom: "15px",
                        borderRadius: "15px",
                        border: "2px solid #d7ecff",
                        backgroundColor: themeStyle.primaryCardColor,
                        color: "black",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "-25px",
                        }}
                      >
                        <h3 style={{}}>{feed["subject"]}</h3>
                      </div>
                      <div
                        style={{
                          marginTop: "-15px",
                          marginBottom: "5px",
                          lineHeight: "18px",
                        }}
                      >
                        {feed["description"]}
                      </div>
                    </Box>
                  </Fragment>
                ))
              )}
            </Grid>

            <Grid item md={4} style={{ marginTop: "-23px" }}>
              <h3>Update Resignation Button Visibility</h3>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "14px",
                }}
              >
                Select User
              </span>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                variant={"outlined"}
                fullWidth
                value={userIndex}
                onChange={(e) => {
                  handleUserChange(e);
                }}
              >
                {getUsersList()}
              </Select>

              <br></br>
              <br></br>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    fontSize: "15px",
                    height: "35px",
                    marginTop: "-1px",
                    backgroundColor: themeStyle.primaryColor,
                  }}
                  onClick={() => submitFeedback()}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  <h4>SUBMIT FEEDBACK</h4>
                </Button>
                {isSubmitting ? (
                  <div
                    style={{
                      marginTop: "60px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                    <h3>Submitting...</h3>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (state.auth.user.is_staff || state.auth.user.is_hr) {
          if (isLoading) {
            return (
              <Fragment>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    marginTop: "46vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    padding: "100px 50px 90px 90px",
                    backgroundColor: themeStyle.backgroundColor,
                    height: "100vh",
                    width: "100vw",
                  }}
                >
                  {getContent()}
                </div>
              </Fragment>
            );
          }
        } else {
          dispatch({
            type: "AUTH_ERROR",
          });
          return <Navigate to="/login" />;
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      dispatch({
        type: "AUTH_ERROR",
      });
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Feedback;
