import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage, loadUser } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { themeStyle, themeStyleDark } from "../components/Components";
import PublishIcon from "@mui/icons-material/Publish";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Payslips() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [payslipSubject, setPayslipSubject] = useState("");

  const colorList = [
    "#e5f5e0",
    state.auth.theme == "dark"
      ? themeStyleDark.secondaryButtonColor
      : themeStyle.secondaryButtonColor,
    "#fee6ce",
  ];
  const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
  const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

  const [isLoading, setIsLoading] = useState(true);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const [payslipId, setPayslipId] = useState("");
  const [email, setEmail] = useState("");
  const [leaveDate, setPayslipDate] = useState("");
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [currentPayslips, setCurrentPayslips] = useState([]);

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  function onFileChange(event) {
    // Update the state
    setSelectedFile(event.target.files[0]);
    setOpen(true);
  }

  function deletePayslip() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = users[userIndex]["email"];
    body["payslip_id"] = payslipId;

    axios
      .post(API_URL + "delete_payslip", body, config)
      .then(() => {
        setIsSubmitting(false);
        getData();
        createAlert("SUCCESS", "Success.", "Payslip deleted.");
        setOpen2(false);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setUsers(res.data);
      setCurrentPayslips(res.data[userIndex]["payslips"]);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (state.auth.user.is_staff || state.auth.user.is_hr) {
        getData();
      } else {
        setIsLoading(false);
        setCurrentPayslips(JSON.parse(state.auth.user.payslips));
      }
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    if (state.auth.user.is_staff || state.auth.user.is_hr) {
      getData();
    }
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setCurrentPayslips(users[e.target.value]["payslips"]);
  }

  function getPageContent() {
    if (!state.auth.user.is_staff) {
      return (
        <Fragment>
          {JSON.parse(state.auth.user.payslips).length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No payslips uploaded for you.</h4>
              </div>
            </div>
          ) : (
            ""
          )}

          {JSON.parse(state.auth.user.payslips).map((payslip, index) => (
            <Box
              p={2}
              onClick={() =>
                window.open(
                  API_URL + "media/" + payslip["path"],
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }
              style={{
                cursor: "pointer",
                height: "80px",
                marginBottom: "15px",
                borderRadius: "15px",
                backgroundColor: colorList[1],
                color: "black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItemsL: "center",
                }}
              >
                <div style={{ width: "90%" }}>
                  <div
                    style={{
                      margin: "-25px 0px 0px -3px",
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: "bold",
                        lineHeight: "20px",
                        color: colorList2[1],
                      }}
                    >
                      {payslip["subject"]}
                    </h3>
                  </div>
                  <div
                    style={{
                      margin: "-15px 0px 0px -3px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: colorList2[1],
                    }}
                  >
                    {moment(payslip["date"], "DD-MM-YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "grey",
                    }}
                  >
                    Uploaded on:{" "}
                    {moment(payslip["upload_date"], "DD-MM-YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                  </div>
                </div>
              </div>
            </Box>
          ))}
        </Fragment>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item md={8}>
              {currentPayslips.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No Payslips for this user.
                    </h4>
                  </div>
                </div>
              ) : (
                ""
              )}

              {currentPayslips.map((payslip, index) => (
                <Box
                  style={{
                    borderRadius: "6px",
                    border: "1.3px solid #d7ecff",
                    backgroundColor: themeStyle.primaryCardColor,
                    boxShadow: "none",
                    padding: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      margin: "-5px 0px 0px 0px",
                      fontSize: "18px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.secondaryButtonTextColor
                          : themeStyle.secondaryButtonTextColor,
                    }}
                  >
                    {payslip["subject"]}
                  </div>
                  <div
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    }}
                  >
                    {moment(payslip["date"], "DD-MM-YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                  </div>
                  <div style={{ marginTop: "-5px" }}>
                    {moment(payslip["upload_date"], "DD-MM-YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "-5px",
                      marginRight: "-5px",
                    }}
                  >
                    <Fab
                      size="small"
                      style={{
                        transform: "scale(0.8)",
                        boxShadow: "none",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                      onClick={() => {
                        setPayslipId(payslip["id"]);
                        setOpen2(true);
                      }}
                      aria-label="add"
                    >
                      <DeleteIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </Fab>
                  </div>
                </Box>
              ))}
            </Grid>
            <Grid item md={4}>
              <div style={{ marginTop: "-25px", marginBottom: "-15px" }}>
                <h3>Upload Payslip</h3>
              </div>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "16px",
                }}
              >
                Select User
              </span>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                variant={"outlined"}
                fullWidth
                value={userIndex}
                onChange={(e) => handleUserChange(e)}
              >
                {getUsersList()}
              </Select>
              <br></br>
              <div
                style={{
                  margin: "15px 0px 5px 0px",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "16px",
                }}
              >
                Subject
              </div>
              <TextField
                value={payslipSubject}
                style={{ marginTop: "2px" }}
                onChange={(e) => setPayslipSubject(e.target.value)}
                fullWidth
                placeholder="Title for Payslip"
                variant="outlined"
                size="small"
              />
              <br></br>
              <br></br>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "16px",
                }}
              >
                Choose a date
              </span>
              <br></br>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{
                    height: "40px",
                    width: "100%",
                    marginTop: "5px",
                    marginBottom: "20px",
                  }}
                  inputFormat="dd-MM-yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        width: "100%",
                        marginTop: "2px",
                        marginBottom: "5px",
                        height: "40px",
                      }}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <div style={{ marginTop: "25px" }}>
                <label
                  style={{ width: "100%" }}
                  htmlFor="contained-button-file"
                >
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => onFileChange(e)}
                  />

                  <Button
                    style={{
                      width: "100%",
                      fontSize: "15px",
                      height: "35px",
                      backgroundColor: themeStyle.primaryColor,
                    }}
                    variant="contained"
                    component="span"
                  >
                    <h4>Submit payslip</h4>
                  </Button>
                </label>
              </div>
              {isSubmitting ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton size="small">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              />
            </IconButton>
          </div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  setOpen(false);
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                style={{
                  width: "100%",
                  fontSize: "15px",
                  height: "35px",
                  backgroundColor: themeStyle.primaryColor,
                }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function onFileUpload() {
    setIsSubmitting(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, selectedFile.name);

    // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        // "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };

    formData.append("email", users[userIndex]["email"]);
    formData.append("date", moment(selectedDate).format("DD-MM-YYYY"));
    formData.append("upload_date", moment().format("DD-MM-YYYY"));

    formData.append("payslip_subject", payslipSubject);

    axios
      .post(API_URL + "add_payslip", formData, config)
      .then(() => {
        setIsSubmitting(false);
        getData();
        createAlert("SUCCESS", "Success.", "Payslip Uploaded.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });

    setOpen(false);
    document.getElementById("contained-button-file").value = "";
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                <CircularProgress style={{ color: themeStyle.primaryColor }} />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    {fileData()}
                  </div>
                </Fade>
              </Modal>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Delete this payslip entry?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen2(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => deletePayslip()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  height: "100vh",
                  padding: "75px 15px 20px 85px",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    }
  } else {
    return (
      <Fragment>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Payslips;
