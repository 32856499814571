import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { logout } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { alertMessage } from "../actions/auth";
import { themeStyle } from "../components/Components";
export default function Oops() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Fragment>
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 1s",
          opacity: loaded ? 1 : 0,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage:
              "linear-gradient(to right, rgba(33,41,100,0.9),  rgba(33, 41, 100, 0.9)), url(https://images.unsplash.com/photo-1668247617628-76a200dacff9?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
            height: "100%",
            paddingTop: "50px",
          }}
        >
          <AppBar
            position="fixed"
            style={{
              backgroundColor: themeStyle.backgroundColor,
            }}
          >
            <Toolbar
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ maxHeight: "50px", margin: "10px" }}
                src={require("../img/appbar.png")}
              />
              <a
                style={{ textDecoration: "none" }}
                href="https://manager.gogoz.in/"
              >
                <Button
                  style={{
                    backgroundColor: themeStyle.backgroundColor,
                    color: "black",
                    fontSize: "16px",
                    marginTop: "3px",
                  }}
                >
                  <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
                </Button>
              </a>
            </Toolbar>
          </AppBar>
          <div
            style={{
              padding: "50px",
              backgroundColor: themeStyle.backgroundColor,
              textAlign: "center",
              borderRadius: "6px",
            }}
          >
            <div style={{ marginTop: "-30px" }}>404 not found.</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
